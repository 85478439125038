
@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeng/resources/themes/luna-amber/theme.css';
@import '../node_modules/primeicons/primeicons.css';

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: rgba(0, 0, 0, 0.8)!important;
    border-color: #302209!important;
    color: #f7d9ad!important;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
    box-shadow: 0 0 0 0.1rem #f7d9ad;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:hover {
    text-decoration: none;
    color: #f7d9ad!important;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    background: rgba(0, 0, 0, 0.8);
}

/* .p-accordion .p-accordion-content {
    padding: 0.571rem 1rem;
    border: 1px solid #191919;
    background: #323232;
    color: #dedede;
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
} */